import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { ConfirmationDialogService } from '../confirmation-dialog/confirmation-dialog.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiServiceService } from '../../../core/services/api-service.service';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  selectedOption: any;
  data: any;
  editData: any;
  teamMembers: any;
  team: any;
  buttonTxt = 'Submit';
  header: any;

  submitForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    team: new FormControl('', [Validators.required]),
    refurl: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required])
  });

  constructor(
    private confirmationDialogService: ConfirmationDialogService,
    private router: Router,
    private toastr: ToastrService,
    private apiService: ApiServiceService,
    private activatedroute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getList();
    this.activatedroute.queryParams
      .subscribe(params => {
        this.data = params['id'] || false;
        if (this.data) {
          this.buttonTxt = 'Update'
          this.apiService.getData().subscribe((response: any) => {
            response.forEach(element => {
              if (element.id == this.data) {
                this.editData = element
              }
            });
            this.setDefault();
          })
        }
      });
    this.header = this.data ? 'Update Data' : 'Submit Data';
  }

  getList() {
    this.apiService.getMembersAndTeam().subscribe((response) => {
      this.teamMembers = response['teamMembers']
      this.team = response['teams']
    })
  }

  setDefault() {
    let defaultValue = {
      name: this.editData ?.name,
      team: this.editData ?.team,
      refurl: this.editData ?.refurl,
      description: this.editData ?.description
    };
    this.submitForm.setValue(defaultValue);
  }

  reset() {
    this.submitForm.reset();
  }
  onSubmit() {
    if (this.data) {
      const editedObject = {
        id: this.data,
        ...this.submitForm.value
      }
      this.apiService.editData(editedObject).subscribe((response) => {
        if (response) {
          this.toast();
          this.router.navigate(['list-reference']);
        }
      },
        (error) => {
          this.confirmationDialogService.confirm('', 'No Network Connectivity')
        })
    }
    else {
      this.apiService.submitData(this.submitForm.value).subscribe((response) => {
        if (response['id']) {
          this.toast();
          this.router.navigate(['list-reference'])
        }
      },
        (error) => {
          this.confirmationDialogService.confirm('', 'No Network Connectivity')
        }
      );
    }
  }

  public openConfirmationDialog() {
    const message = this.data ? 'Do you really want to Update the record ?' : 'Do you really want to Submit the record ?';
    this.confirmationDialogService.confirm('Please confirm', message)
      .then((confirmed) => {
        if (confirmed) {
          this.onSubmit();
        }
      })
  }

  toast() {
    const message = this.data ? 'Data Updated Successfully' : 'Data Added Successfully';
    this.toastr.success(message, '',
      {
        timeOut: 1000,
        positionClass: 'toast-top-center'
      }
    );
  }
}
