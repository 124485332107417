<nav class="navbar navbar-light bg-light">
    <span class="navbar-brand  h1">{{header}}</span>
</nav>
<div>
    <form [formGroup]="submitForm">
        <div class="form-row">
            <div class="form-group required col-md-6">
                <label class='control-label'>Name&#160;</label>
                <input placeholder="Name" class="form-control" formControlName="name" type="text" list="names">
                <datalist id="names">
                    <option *ngFor="let item of teamMembers">
                        {{item}}
                    </option>
                </datalist>
            </div>
            <div class="form-group required col-md-6">
                <label class='control-label' for="inputState">Team&#160;</label>
                <input placeholder="Team" class="form-control" formControlName="team" autocomplete="off" type="text" name="city" list="cityname">
                <datalist id="cityname">
                    <option *ngFor="let item of team">
                        {{item}}
                    </option>
                </datalist>
            </div>
        </div>
        <div class="form-row">
        </div>
        <div class="form-group required">
            <label class='control-label'>URL&#160;</label>
            <input formControlName="refurl" autocomplete="off" type="text" class="form-control" placeholder="URL">
        </div>
        <div class="form-group required">
            <label class='control-label'>Description&#160;</label>
            <textarea formControlName="description" placeholder="Description" class="form-control" rows="3">
        </textarea>
        </div>
    </form>
    <button (click)="openConfirmationDialog()" [disabled]="!submitForm.valid" type="submit" class="btn btn-primary">
        {{buttonTxt}}
    </button>
    <span class="btn-padding">
        <button (click)="reset()" class="btn btn-secondary">Reset</button>
    </span>
</div>