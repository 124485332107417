import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

const URL = 'http://localhost:8081/api/refrence/all'
const REF_URL = 'http://localhost:8081/api/refrence'
const LDAP_URL = 'http://localhost:8081/api/user/details/'
const UPLOAD_RESUME = 'http://localhost:8081/api/referralcampaign/save'
const UPLOAD_RESUME_EXT = 'https://careers.tataelxsi.com/api/jobapplicationportal/save-external'
//const UPLOAD_RESUME_EXT = 'http://localhost:8081/api/jobapplicationportal/save-external'
@Injectable({
      providedIn: 'root'
})
export class ApiServiceService {

      constructor(private httpClient: HttpClient) { }

      getMembersAndTeam() {
            return this.httpClient.get(URL);
      }

      getTeam() {
            return this.httpClient.get(REF_URL);
      }

      editData(value) {
            const headers = { 'content-type': 'application/json' }
            const params = new HttpParams()
                  .set('description', value.description)
                  .set('id', value.id)
                  .set('name', value.name)
                  .set('refurl', value.refurl)
                  .set('team', value.team);
            return this.httpClient.put(REF_URL,
                  value, { 'headers': headers, 'params': params, responseType: 'text' })
      }

      deleteData(id) {
            return this.httpClient.delete(REF_URL + '/' + id, { responseType: 'text' })
      }

      submitData(value) {
            const headers = { 'content-type': 'application/json' }
            const params = new HttpParams()
                  .set('description', value.description)
                  .set('name', value.name)
                  .set('refurl', value.refurl)
                  .set('team', value.team);
            const body = JSON.stringify(value);
            return this.httpClient.post(REF_URL,
                  body, { 'headers': headers, 'params': params })

      }

      getData() {
            return this.httpClient.get(REF_URL)
      }

      getNtidDetails(ntidInfo) {
            const httpOptions = {
                  headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic ' + btoa(ntidInfo.userNtid + ':' + ntidInfo.password)
                  })
            }
            return this.httpClient.get(LDAP_URL + ntidInfo.reqNtid, httpOptions);
      }

      uploadResume(formData) {
            return this.httpClient.post(UPLOAD_RESUME, formData, {
                  responseType: 'text',
                  observe: 'response'
            })
      }
      uploadResumeExt(formData) {
            return this.httpClient.post(UPLOAD_RESUME_EXT, formData, {
                  responseType: 'text',
                  observe: 'response'
            })
      }
}





