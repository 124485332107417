<header style="background-color: #3d85c6">
    <div class="center">
      <div class="leftk">
        <img src="assets/TataElxsi.png" />
      </div>
      <div class="rightk">
        <img src="assets/tata-logo.png" />
      </div>
    </div>
  </header>
<div class="content-wrapper px-4 py-2">
  <router-outlet></router-outlet>
</div>
  <footer class="main-footer footer-col">
    <div class="pull-right hidden-xs"></div>
    <strong>Copyright © 2021 <a>Tata Elxsi</a>.</strong> All rights reserved.
  </footer>
  <aside class="control-sidebar control-sidebar-dark"></aside>  

