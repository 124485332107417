import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormComponent } from './shared/components/form/form.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { AgGridModule } from 'ag-grid-angular';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationDialogComponent } from
  './shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import { HttpClientModule } from '@angular/common/http';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ApplicationFormModule } from './modules/application-form/application-form.module';

@NgModule({
  declarations: [
    AppComponent,
    FormComponent,
    ConfirmationDialogComponent,
     ],
  imports: [
    NgbModule,
    BrowserModule,
    AppRoutingModule,
    Ng2SmartTableModule,
    AgGridModule.withComponents([
      ]),
    ReactiveFormsModule,
    FormsModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    MatTooltipModule,
    NgbPopoverModule,
    HttpClientModule,
    NgxJsonViewerModule,
    ApplicationFormModule
  ],
  exports: [Ng2SmartTableModule,
    AgGridModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    ToastrModule,
    MatTooltipModule,
    NgbPopoverModule,
    FormsModule,
    ReactiveFormsModule,
    NgxJsonViewerModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
