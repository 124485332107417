<nav class="navbar-light bg-light">
    <h4 style="text-align:center"> Job Application Portal </h4>
</nav>
<div>
    <form [formGroup]="reportJson" novalidate (ngSubmit)="onSubmit()">
        <h6>
            <b>Applicant Details</b>
        </h6>
        <div class="row">
            <div class="form-group required col-md-6">
                <label class='control-label'>Full Name&#160;</label>
                <input type = "text" autocomplete="on" placeholder="Enter Full Name" class="form-control" formControlName="candidateName">
                <div *ngIf="f.candidateName.touched && f.candidateName.invalid" class="alert alert-danger">
                    <div *ngIf="f.candidateName.errors.required">Name Is Required.</div>
                    <div *ngIf="f.candidateName.errors.minlength">Name Should Be Atleast 3 Characters.</div>
                    <div *ngIf="f.candidateName.errors.pattern">Name Should Contain Only Alphabets.</div>
                </div>
            </div>

            <div class="form-group required col-md-6">
                <label for="inputPassword" class='control-label'>Email ID&#160;</label>
                <input type="email" autocomplete="on" placeholder="Enter Email ID" formControlName="emailID"
                    class="form-control" id="inputEmail">
                <div *ngIf="f.emailID.touched && f.emailID.invalid" class="alert alert-danger">
                    <div *ngIf="f.emailID.errors.required">Email Is Required.</div>
                    <div *ngIf="f.emailID.errors.email">Please, Enter A Valid Email ID.</div>
                </div>
            </div>

            <div class="form-group required col-md-2">
                <label class='control-label'>Country Code&#160;</label>
                <select formControlName="countryCode" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select Country Code</option>
                    <option>+91 - INDIA</option>
                    <option>+93 - AFGHANISTAN</option>
                    <option>+1-907 - ALASKA (USA)</option>
                    <option>+355 - ALBANIA</option>
                    <option>+213 - ALGERIA</option>
                    <option>+1-684 - AMERICAN SAMOA</option>
                    <option>+376 - ANDORRA</option>
                    <option>+244 - ANGOLA</option>
                    <option>+1-264 - ANGUILLA</option>
                    <option>+1-268 - ANTIGUA & BARBUDA</option>
                    <option>+54 - ARGENTINA</option>
                    <option>+374 - ARMENIA</option>
                    <option>+297 - ARUBA</option>
                    <option>+247 - ASCENSION</option>
                    <option>+61 - AUSTRALIA</option>
                    <option>+43 - AUSTRIA</option>
                    <option>+994 - AZERBAIJAN</option>
                    <option>+1-242 - BAHAMAS</option>
                    <option>+973 - BAHRAIN</option>
                    <option>+880 - BANGLADESH</option>
                    <option>+1-246 - BARBADOS</option>
                    <option>+375 - BELARUS</option>
                    <option>+32 - BELGIUM</option>
                    <option>+501 - BELIZE</option>
                    <option>+229 - BENIN</option>
                    <option>+1-441 - BERMUDA</option>
                    <option>+975 - BHUTAN</option>
                    <option>+591 - BOLIVIA</option>
                    <option>+387 - BOSNIA / HERZEGOVINA</option>
                    <option>+267 - BOTSWANA</option>
                    <option>+55 - BRAZIL</option>
                    <option>+1-284 - BRITISH VIRGINISLANDS</option>
                    <option>+673 - BRUNEI</option>
                    <option>+359 - BULGARIA</option>
                    <option>+226 - BURKINA FASO</option>
                    <option>+257 - BURUNDI</option>
                    <option>+855 - CAMBODIA</option>
                    <option>+237 - CAMEROON</option>
                    <option>+1  - CANADA</option>
                    <option>+238 - CAPE VERDE</option>
                    <option>+1-345 - CAYMAN ISLANDS</option>
                    <option>+236 - CENTRAL AFRICANREPUBLIC</option>
                    <option>+235 - CHAD</option>
                    <option>+56 - CHILE</option>
                    <option>+86 - CHINA</option>
                    <option>+57 - COLOMBIA</option>
                    <option>+269 - COMOROS</option>
                    <option>+242 - CONGO</option>
                    <option>+243 - CONGO DEM. REP.(ZAIRE)</option>
                    <option>+682 - COOK ISLAND</option>
                    <option>+506 - COSTA RICA</option>
                    <option>+385 - CROATIA</option>
                    <option>+53 - CUBA</option>
                    <option>+357 - CYPRUS</option>
                    <option>+420 - CZECH REPUBLIC</option>
                    <option>+45 - DENMARK</option>
                    <option>+246 - DIEGO GARCIA</option>
                    <option>+253 - DJIBOUTI</option>
                    <option>+1-767 - DOMINICA</option>
                    <option>+1-809 - DOMINICAN REPUBLIC</option>
                    <option>+670 - EAST TIMOR</option>
                    <option>+593 - ECUADOR</option>
                    <option>+20 - EGYPT</option>
                    <option>+503 - EL SALVADOR</option>
                    <option>+240 - EQUATORIAL GUINEA</option>
                    <option>+291 - ERITREA</option>
                    <option>+372 - ESTONIA</option>
                    <option>+251 - ETHIOPIA</option>
                    <option>+500 - FALKLAND ISLANDS</option>
                    <option>+298 - FAROE ISLANDS</option>
                    <option>+679 - FIJI</option>
                    <option>+358 - FINLAND</option>
                    <option>+33 - FRANCE</option>
                    <option>+594 - FRENCH GUIANA</option>
                    <option>+689 - FRENCH POLYNESIA</option>
                    <option>+241 - GABON</option>
                    <option>+220 - GAMBIA</option>
                    <option>+995 - GEORGIA</option>
                    <option>+49 - GERMANY</option>
                    <option>+233 - GHANA</option>
                    <option>+350 - GIBRALTAR</option>
                    <option>+30 - GREECE</option>
                    <option>+299 - GREENLAND</option>
                    <option>+1-473 - GRENADA</option>
                    <option>+590 - GUADALOUPE</option>
                    <option>+1-671 - GUAM</option>
                    <option>+502 - GUATEMALA</option>
                    <option>+224 - GUINEA</option>
                    <option>+245 - GUINEA BISSAU</option>
                    <option>+592 - GUYANA</option>
                    <option>+509 - HAITI</option>
                    <option>+1-808 - HAWAII (USA)</option>
                    <option>+504 - HONDURAS</option>
                    <option>+852 - HONG KONG</option>
                    <option>+36 - HUNGARY</option>
                    <option>+354 - ICELAND</option>
                    <option>+62 - INDONESIA</option>
                    <option>+98 - IRAN</option>
                    <option>+964 - IRAQ</option>
                    <option>+353 - IRELAND</option>
                    <option>+972 - ISRAEL</option>
                    <option>+39 - ITALY</option>
                    <option>+225 - IVORY COAST</option>
                    <option>+1-876 - JAMAICA</option>
                    <option>+81 - JAPAN</option>
                    <option>+962 - JORDAN</option>
                    <option>+7  - KAZAKHSTAN</option>
                    <option>+254 - KENYA</option>
                    <option>+686 - KIRIBATI</option>
                    <option>+850 - KOREA (NORTH)</option>
                    <option>+82 - KOREA SOUTH</option>
                    <option>+965 - KUWAIT</option>
                    <option>+996 - KYRGHYZSTAN</option>
                    <option>+856 - LAOS</option>
                    <option>+371 - LATVIA</option>
                    <option>+961 - LEBANON</option>
                    <option>+266 - LESOTHO</option>
                    <option>+231 - LIBERIA</option>
                    <option>+218 - LIBYA</option>
                    <option>+423 - LIECHTENSTEIN</option>
                    <option>+370 - LITHUANIA</option>
                    <option>+352 - LUXEMBOURG</option>
                    <option>+853 - MACAU</option>
                    <option>+389 - MACEDONIA</option>
                    <option>+261 - MADAGASCAR</option>
                    <option>+265 - MALAWI</option>
                    <option>+60 - MALAYSIA</option>
                    <option>+960 - MALDIVES</option>
                    <option>+223 - MALI</option>
                    <option>+356 - MALTA</option>
                    <option>+1-670 - MARIANA IS.(SAIPAN)</option>
                    <option>+692 - MARSHALL ISLANDS</option>
                    <option>+596 - MARTINIQUE(FRENCHANTILLES)</option>
                    <option>+222 - MAURITANIA</option>
                    <option>+230 - MAURITIUS</option>
                    <option>+269 - MAYOTTE</option>
                    <option>+52 - MEXICO</option>
                    <option>+691 - MICRONESIA</option>
                    <option>+373 - MOLDOVA</option>
                    <option>+377 - MONACO</option>
                    <option>+976 - MONGOLIA</option>
                    <option>+1-664 - MONTSERRAT</option>
                    <option>+212 - MOROCCO</option>
                    <option>+258 - MOZAMBIQUE</option>
                    <option>+95 - MYANMAR</option>
                    <option>+264 - NAMIBIA</option>
                    <option>+674 - NAURU</option>
                    <option>+977 - NEPAL</option>
                    <option>+31 - NETHERLANDS</option>
                    <option>+599 - NETHERLANDS ANTILLES</option>
                    <option>+687 - NEW CALEDONIA</option>
                    <option>+64 - NEW ZEALAND</option>
                    <option>+505 - NICARAGUA</option>
                    <option>+227 - NIGER</option>
                    <option>+234 - NIGERIA</option>
                    <option>+683 - NIUE ISLAND</option>
                    <option>+47 - NORWAY</option>
                    <option>+968 - OMAN</option>
                    <option>+92 - PAKISTAN</option>
                    <option>+680 - PALAU</option>
                    <option>+970 - PALESTINE</option>
                    <option>+507 - PANAMA</option>
                    <option>+675 - PAPUA NEW GUINEA</option>
                    <option>+595 - PARAGUAY</option>
                    <option>+51 - PERU</option>
                    <option>+63 - PHILIPPINES</option>
                    <option>+48 - POLAND</option>
                    <option>+351 - PORTUGAL</option>
                    <option>+1-787 - PUERTO RICO (I) (USA)</option>
                    <option>+1-939 - PUERTO RICO (II)(USA)</option>
                    <option>+974 - QATAR</option>
                    <option>+262 - REUNION</option>
                    <option>+40 - ROMANIA</option>
                    <option>+7  - RUSSIA</option>
                    <option>+250 - RWANDA</option>
                    <option>+685 - SAMOA WESTERN</option>
                    <option>+378 - SAN MARINO</option>
                    <option>+239 - SAO TOME &PRINCIPE</option>
                    <option>+966 - SAUDI ARABIA</option>
                    <option>+221 - SENEGAL</option>
                    <option>+248 - SEYCHELLES</option>
                    <option>+232 - SIERRA LEONE</option>
                    <option>+65 - SINGAPORE</option>
                    <option>+421 - SLOVAKIA</option>
                    <option>+386 - SLOVENIA</option>
                    <option>+677 - SOLOMON ISLANDS</option>
                    <option>+252 - SOMALIA</option>
                    <option>+27 - SOUTH AFRICA</option>
                    <option>+34 - SPAIN</option>
                    <option>+94 - SRI LANKA</option>
                    <option>+290 - ST HELENA</option>
                    <option>+1-869 - ST KITTS & NEVIS</option>
                    <option>+1-758 - ST LUCIA</option>
                    <option>+1-784 - ST VINCENT &GRENADINES</option>
                    <option>+508 - ST. PIERRE &MIQUELON</option>
                    <option>+249 - SUDAN</option>
                    <option>+597 - SURINAM</option>
                    <option>+268 - SWAZILAND</option>
                    <option>+46 - SWEDEN</option>
                    <option>+41 - SWITZERLAND</option>
                    <option>+963 - SYRIA</option>
                    <option>+886 - TAIWAN</option>
                    <option>+992 - TAJIKISTAN</option>
                    <option>+255 - TANZANIA</option>
                    <option>+66 - THAILAND</option>
                    <option>+228 - TOGO</option>
                    <option>+690 - TOKELAU</option>
                    <option>+676 - TONGA</option>
                    <option>+1-868 - TRINIDAD & TOBAGO</option>
                    <option>+216 - TUNISIA</option>
                    <option>+90 - TURKEY</option>
                    <option>+993 - TURKMENISTAN</option>
                    <option>+1-649 - TURKS & CAICOSISLANDS</option>
                    <option>+688 - TUVALU</option>
                    <option>+256 - UGANDA</option>
                    <option>+380 - UKRAINE</option>
                    <option>+971 - UNITED ARAB EMIRATES</option>
                    <option>+44 - UNITED KINGDOM</option>
                    <option>+598 - URUGUAY</option>
                    <option>+998 - UZBEKISTAN</option>
                    <option>+678 - VANUATU</option>
                    <option>+39 - VATICAN CITY</option>
                    <option>+58 - VENEZUELA</option>
                    <option>+84 - VIETNAM</option>
                    <option>+1-340 - VIRGIN ISLAND (USA)</option>
                    <option>+681 - WALLIS & FUTUNA</option>
                    <option>+967 - YEMEN</option>
                    <option>+381 - YUGOSLAVIA (SERBIA)</option>
                    <option>+260 - ZAMBIA</option>
                    <option>+255 - ZANZIBAR</option>
                    <option>+263 - ZIMBABWE</option>
                    </select>
                <div *ngIf="f.countryCode.touched && f.countryCode.invalid" class="alert alert-danger"
                    [hidden]="f.countryCode.valid || f.countryCode.untouched">
                    <div *ngIf="f.countryCode.errors.required">Please Select The Country Code</div>
                </div>
            </div>
            <div class="form-group required col-md-4">
                <label class='control-label'>Mobile Number&#160;</label>
               
                <input maxlength="15" type="tel" autocomplete="on"
                    placeholder="Enter Mobile Number Example: 9876543210" class="form-control"
                    formControlName="phoneNumber">
                <div *ngIf="f.phoneNumber.touched && f.phoneNumber.invalid"
                    class="alert alert-danger">
                    <div *ngIf="f.phoneNumber.errors.required"> Mobile Number Is required.</div>
                    <div *ngIf="f.phoneNumber.errors.pattern">Please Enter A Valid Mobile Number.</div>
                </div>
            </div>


            <div class="form-group required col-md-6">
                <label class='control-label'>Lead Time Required To Join (After Selection)&#160;</label>
                <select formControlName="noticePeriod" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select Notice Period</option>
                    <option>Immediate</option>
                    <option>Less than 30 days</option>
                    <option>31-45 days</option>
                    <option>46-60 days</option>
                    <option>61-90 days</option>
                </select>
                <div *ngIf="f.noticePeriod.touched && f.noticePeriod.invalid" class="alert alert-danger"
                    [hidden]="f.noticePeriod.valid || f.noticePeriod.untouched">
                    <div *ngIf="f.noticePeriod.errors.required">Please Select Time Required To Join</div>
                </div>
            </div>

            <div class="form-group required col-md-2">
                <label class='control-label'>Total Experience&#160;</label>
                <select formControlName="experienceRangeYear" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select Years</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                    <option>13</option>
                    <option>14</option>
                    <option>15</option>
                    <option>16</option>
                    <option>17</option>
                    <option>18</option>
                    <option>19</option>
                    <option>20</option>
                    <option>21</option>
                    <option>22</option>
                    <option>23</option>
                    <option>24</option>
                    <option>25</option>
                    <option>26</option>
                    <option>27</option>
                    <option>28</option>
                    <option>29</option>
                    <option>30</option>
                </select>
                <div *ngIf="f.experienceRangeYear.touched && f.experienceRangeYear.invalid" class="alert alert-danger"
                    [hidden]="f.experienceRangeYear.valid || f.experienceRangeYear.untouched">
                    <div *ngIf="f.experienceRangeYear.errors.required">Please Select Years Of Experience</div>
                </div>
            </div>

            <div class="form-group col-md-4">
                <label class='control-label'>&#160;</label>
                <!-- <label class='control-label'>Experience in Years &#160;</label> -->
                <select formControlName="experienceRangeMonth" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select Months</option>
                    <option>00</option>
                    <option>01</option>
                    <option>02</option>
                    <option>03</option>
                    <option>04</option>
                    <option>05</option>
                    <option>06</option>
                    <option>07</option>
                    <option>08</option>
                    <option>09</option>
                    <option>10</option>
                    <option>11</option>
                </select>
                <div *ngIf="f.experienceRangeMonth.touched && f.experienceRangeMonth.invalid" class="alert alert-danger"
                    [hidden]="f.experienceRangeMonth.valid || f.experienceRangeMonth.untouched">
                    <div *ngIf="f.experienceRangeMonth.errors.required">Please Select Months Of Experience</div>
                </div>
            </div>



            <div class="form-group required col-md-6">
                <label class='control-label'>Current CTC&#160;</label>
                <input maxlength="5" autocomplete="on" placeholder="Enter Current CTC in Lakhs Per Annum (INR) Example: 12.34"
                    class="form-control" formControlName="currentCTC">
                <div *ngIf="f.currentCTC.touched && f.currentCTC.invalid " class="alert alert-danger"
                    [hidden]="f.currentCTC.valid || f.currentCTC.untouched">
                    <div *ngIf="f.currentCTC.errors.invalid">Entered Current CTC Is Not Valid.</div>
                    <div *ngIf="f.currentCTC.errors.pattern;else elseBlock">Enter A Valid Current CTC In Lakhs Per Annum (INR)
                        Example: 12.34.</div>
                    <ng-template #elseBlock>Current CTC Cannot Be More Than 99.99 Or Blank.</ng-template>
                </div>
            </div>

            <div class="form-group required col-md-6">
                <label class='control-label'>Expected CTC&#160;</label>
                <input maxlength="5" autocomplete="on" placeholder="Enter Expected CTC in Lakhs Per Annum (INR) Example: 12.34"
                    class="form-control" formControlName="expectedCTC">
                <div *ngIf="f.expectedCTC.touched && f.expectedCTC.invalid " class="alert alert-danger"
                    [hidden]="f.expectedCTC.valid || f.expectedCTC.untouched">
                    <div *ngIf="f.expectedCTC.errors.invalid">Entered Expected CTC Is Not Valid.</div>
                    <div *ngIf="f.expectedCTC.errors.pattern;else elseBlock">Enter A Valid Expected CTC In Lakhs Per
                        Annum (INR) Example: 12.34.</div>
                    <ng-template #elseBlock>Expected CTC Cannot Be More Than 99.99 Or Blank.</ng-template>
                </div>
            </div>

            <div class="form-group required col-md-3">
                <label class='control-label'>Is Expected CTC Negotiable&#160;</label>
                <select formControlName="salaryNegotiable" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select An Option</option>
                    <option>Yes</option>
                    <option>No</option>
                </select>
                <div *ngIf="f.salaryNegotiable.touched && f.salaryNegotiable.invalid" class="alert alert-danger"
                    [hidden]="f.salaryNegotiable.valid || f.salaryNegotiable.untouched">
                    <div *ngIf="f.salaryNegotiable.errors.required">Please Select An Option</div>
                </div>
            </div>
            <div class="form-group required col-md-3">
                <label class='control-label'>How Did You Hear About This Position? &#160;</label>
                <select formControlName="howDidYouHear" class="form-control" id="exampleFormControlSelect1">
                    <option [ngValue]="null" disabled selected hidden>Select An Option</option>
                    <option>Tata Elxsi Website</option>
                    <option>Tata Elxsi Employee</option>
                    <option>LinkedIn</option>
                    <option>Facebook</option>
                    <option>Twitter</option>
                    <option>Instagram</option>
                    <option>Job Portals</option>
                    <option>Others</option>
                </select>
                <div *ngIf="f.howDidYouHear.touched && f.howDidYouHear.invalid" class="alert alert-danger"
                    [hidden]="f.howDidYouHear.valid || f.howDidYouHear.untouched">
                    <div *ngIf="f.howDidYouHear.errors.required">Please Select An Option</div>
                </div>
            </div>
            <div class="form-group required col-md-6">
                <label class='control-label'>Upload Resume&#160;</label>
                &nbsp;&nbsp;
                <input accept=".doc,.docx,.pdf,.rtf,.odt" class="file" formControlName="fileControl" type="file"
                    (change)="fileChange($event)" />
            </div>
            <div *ngIf="f.fileControl.touched && f.fileControl.invalid" class="alert alert-danger"
                [hidden]="f.fileControl.valid || f.fileControl.untouched">
                <div *ngIf="f.fileControl.errors.required">Please Select A File To Upload.</div>
            </div>
        </div>

    </form>
    <br />
<div class="form-group required">
    <label class='control-label'>All Fields Marked With </label> <label style="margin-left:0.2em"> Are Mandatory.</label></div>
    <button [disabled]="!reportJson.valid" (click)=" onSubmit()" type="submit" class="btn btn-primary">
        Apply
    </button>

    <br>
    <br>
    <div *ngIf="loading">
        <div class="spinner-border text-success" role="status">
        </div>
        <label>Uploading.. Please Wait..</label>
    </div>
</div>