import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormControl, AbstractControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { ApiServiceService } from '../../../../core/services/api-service.service';
import { ConfirmationDialogService } from '../../../../shared/components/confirmation-dialog/confirmation-dialog.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

let jobId,sourceOfClick,currentDate;

@Component({
  selector: 'app-form-details',
  templateUrl: './form-details.component.html',
  styleUrls: ['./form-details.component.scss'],
  providers: [DatePipe]
})
export class AppDataComponent implements OnInit {
  file: File | null = null;
  fileList: FileList;
  fileNotSelected: boolean = true;
  loading: boolean = false;
  reportJson = new FormGroup({
    emailID: new FormControl('', [Validators.required,Validators.email]),
    candidateName: new FormControl('', [Validators.required,Validators.minLength(3),Validators.pattern("[a-zA-Z ]*")]),
    noticePeriod: new FormControl('null', [Validators.required]),
    experienceRangeYear: new FormControl('null', [Validators.required]),
    experienceRangeMonth: new FormControl('null', [Validators.required]),
    currentCTC: new FormControl('', [Validators.required,Validators.maxLength(5),Validators.max(99.99),Validators.pattern("[0-9]+(?:\.[0-9]{0,2})?")]),
    expectedCTC: new FormControl('', [Validators.required,Validators.maxLength(5),Validators.max(99.99),Validators.pattern("[0-9]+(?:\.[0-9]{0,2})?")]),
    fileControl: new FormControl('', [Validators.required]),
    countryCode: new FormControl('',[Validators.required]),
    salaryNegotiable: new FormControl('',[Validators.required]),
    howDidYouHear: new FormControl('',[Validators.required]),
    phoneNumber: new FormControl('', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]{1,15}$")])
  });

  confirmationModalTextMessage =
 ` Thank you for submitting your application for this opportunity. 
  Please bear in mind that once submitted, the application cannot be changed; 
  therefore, please double-check before submitting. 
  After submitting your application, you will be automatically redirected to our Careers page.`

  get f(){
    return this.reportJson.controls;
  }
  constructor(
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private route: Router,
    private activatedRoute: ActivatedRoute,
    private apiService: ApiServiceService,
    private confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit(): void {
    this.activatedRoute.fragment?.subscribe((fragment: string) => {
      if(fragment.includes("&"))
      {
      jobId=fragment?.split("&")[0];
      sourceOfClick=fragment?.split("&")[1];
      }
      else if(fragment.includes("?referer="))
      {
        jobId=fragment?.split("?referer=")[0];
        sourceOfClick=fragment?.split("?referer=")[1];
      }
      console.log("jobId",jobId);
      console.log("sourceofClick",sourceOfClick);
    });
    this.reset()
    let dateTime = new Date();
    currentDate = this.datePipe.transform(dateTime, "MM-dd-yyyy");
  }

  fileChange(event) {
    this.fileList = null
    this.file = null
    this.fileList = event.target.files;
    if (this.fileList.length > 0) {
      this.file = this.fileList[0];
    }
  }

  onSubmit() {
    let jsonObj = {
      reportJson: {
        "jobCode": jobId,
        "candidateName": this.reportJson.controls.candidateName.value,
        "countryCode":this.reportJson.controls.countryCode.value,
        "phoneNumber":this.reportJson.controls.phoneNumber.value,
        "emailID": this.reportJson.controls.emailID.value,
        "experienceRangeYear": this.reportJson.controls.experienceRangeYear.value,
        "experienceRangeMonth": this.reportJson.controls.experienceRangeMonth.value,
        "currentCTC": this.reportJson.controls.currentCTC.value,
        "expectedCTC": this.reportJson.controls.expectedCTC.value,
        "noticePeriod": this.reportJson.controls.noticePeriod.value,
        "salaryNegotiable":this.reportJson.controls.salaryNegotiable.value,
        "howDidYouHear":this.reportJson.controls.howDidYouHear.value,
        "appliedDate":currentDate,
        "sourceOfClick":sourceOfClick
      }
    };
    let mailID = this.reportJson.get("emailID").value
    let isMailValid = this.validateVendorEmail(mailID)
    let phoneNumber = this.reportJson.get("phoneNumber").value
    let isMobileNumberValid = this.validateMobileNumber(phoneNumber)
    let candidateName = this.reportJson.get("candidateName").value  
    let val = this.validateName(candidateName)

    if (!isMobileNumberValid || !isMailValid || this.reportJson.get("candidateName").value.trim().length == 0
      || !val) {
      let message;
      if (!isMailValid) {
        message = "Please enter a valid mail ID"
      } else if (this.reportJson.get("candidateName").value.trim().length == 0 || !val) {
        message = "Please enter a valid name"
      } else if (!isMobileNumberValid) {
        message = "Please enter a valid Mobile Number"
      } 
      this.toastr.error(message, '',
        {
          timeOut: 1000,
          positionClass: 'toast-top-center'
        }
      );
    }
    else {
      if (isMailValid) {
        this.confirmationDialogService.confirm('Confirm Submission',this.confirmationModalTextMessage)
          .then((confirmed) => {
            if (confirmed) {

              let fileName = `${this.file.name}`
              let formData: FormData = new FormData();
              if (this.file) {
                formData.append('files', this.file, fileName);
              }
              console.log(jsonObj.reportJson);
              formData.append('reportJson', JSON.stringify(jsonObj.reportJson));
              this.loading = true;
              this.apiService.uploadResumeExt(formData).subscribe(
                data => {
                  this.loading = false;
                  if (data['body'].toLowerCase().includes("successfully")) {
                    const message = 'Application Submitted Successfully.'
                    this.toastr.success(message, '',
                      {
                        timeOut: 4500,
                        positionClass: 'toast-top-center',
                        extendedTimeOut: 9000
                      }
                    );
                    this.reportJson.reset();                    
                    window.location.assign('https://tataelxsi.com/careers');
                  }
                  else {
                    const message = data['body']
                    this.toastr.error(message, '',
                      {
                        timeOut: 1000,
                        positionClass: 'toast-top-center'
                      }
                    );
                  }
                }, (error) => {
                  this.loading = false;
                  this.toastr.error("Please Visit https://tataelxsi.com/careers to Select The Right Position To Apply", "", {
                    timeOut: 4500,
                    positionClass: "toast-top-center",
                  });
                }
              )
            }
          })
      }
    }

  }

  validateWhiteSpaces(control: AbstractControl) {

    if ((control.value).trim() === '') {
      return { whiteSpaces: true };
    }
    return null;
  }


  // validate email
  validateVendorEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
    // return true;/
  }

  // validate name
  validateName(name) {
    const re = /^[a-zA-Z ]+$/;
    return re.test(String(name).toLowerCase());
  }

  // validate name
  validateMobileNumber(mobileNumber) {
    const re = /^[0-9]+$/;
    return re.test(String(mobileNumber).toLowerCase());
  }

  reset(): void {
    this.loading = false;
    this.reportJson.reset();
  }

}
