<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
</div>
<div class="modal-body">
  <span style="white-space: pre">  {{message}}</span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="accept()">{{btnOkText}} </button>
  <button type="button" class="btn btn-danger" (click)="decline()"> {{btnCancelText}} </button>

</div>